import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Fonts from './Fonts'
import 'react-dropdown/style.css'
import "animate.css/animate.min.css";

class Index extends React.Component {
  componentDidMount () {
    Fonts()
  }

  render () {
    return <App />
  }
}

ReactDOM.render(<Index />, document.getElementById('root'));



// export default Index
