import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import Axios from 'axios'
import Line from 'components/Line'


class PrivacyPolicy extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   componentDidMount() {

   }

   render () {

      return (
         <div id="terms">
            <div id="terms-inner">
               <h1 className="h2">PRIVACY POLICY</h1>

               <p>1. Terms of Use: This Privacy Policy is subject and in addition to the Term of Use. All terms used in this Privacy Policy are as defined in the Terms of Use unless otherwise defined herein. This Privacy Policy explains: (i) The personal data we process; (ii) How that information is processed; (iii) With whom we may share it; and (iv) The rights available to you in respect to the personal information you have provided to us. This Privacy Policy concerns the information we collect through your use of this website when you contact or request information from us, when you engage us for services, and when we handle personal data in the course of carrying out our commercial activities. This includes personal data of officers or representatives of organisations with which we have a personal relationship with. As referenced in this Privacy Policy:</p>
               <p>“personal data” means any data which relates to a natural person who can be identified from that data or from any other information which is in the possession of, or is likely to come into the possession of, NovaLux or its representatives or service providers. In addition to factual information, it may also include any expression of opinion about an individual and any indication of the intentions of NovaLux or any other person with respect of such individual; and</p>
               <p>“process” includes any operation that is carried out in respect of personal data, including but not limited to, collecting, using, storing, disclosing or otherwise processing personal data.</p>
               <p>2. Commitment to protecting privacy: NovaLux respects your privacy and is committed to protecting the privacy of all personal data collected from you. For the purposes of data protection laws, NovaLux is a user or controller in respect of your personal data. We are responsible for ensuring that we use your personal data in compliance with applicable data protection laws. </p>
               <p>3. Personal data collected: NovaLux may collect and process the following personal data about you:</p>
               <p>(i)   Information that you provide to us or one of our affiliates. This includes, without limitation, information about you that you give to us by filling in online forms or by communicating with us, whether by e-mail or otherwise. The nature of our relationship and the services you are requesting will determine the kind of personal data we might collect and use. This information may include (but is not limited to): 
               <br/>(a)   Basic personal data (such as your name, date of birth, national identification numbers, social security numbers, address, telephone numbers, e-mail addresses, occupation and job title;
               <br/>(b)   When you have an investor account, your username and password to access NovaLux products and services online;
               <br/>(c)   Financial information (such as information relating to your financial condition and details of your income and assets); and
               <br/>(d)   Information that you choose to share with us (whether through the website or otherwise) including any comments or other information you submit to us.</p>
               <p>(ii)  Information we collect or generate about you. This includes, without limitation, information about our business relationships with you and our interactions with you, including any personal data that you provide during telephone and e-mail communications with us which we may monitor and record in order to resolve complaints, improve our services and in order to comply with our legal and regulatory requirements.</p>
               <p>(iii) Information we obtain from other sources: This may include, without limitation:</p>
               <p>(a)   Information from publicly available sources (including third party agencies such as credit reference agencies, fraud prevention agencies, law enforcement agencies, and other publicly accessible sources);</p>
               <p>(b)   Information obtained from independent financial advisors (IFAs), other personal advisors, product providers, and other agents and/or representatives; and</p>
               <p>(c)   Information obtained from sanctions and other background screening providers.</p>
               <p>4. Purposes: NovaLux may process your personal data for the following purposes (to the extent applicable):
               <br/>(i) To provide, manage or administer the products or services provided to you, including processing transactions;
                  <br/>(ii)   To manage and conduct our business, including to enable third party service providers to perform services for us or on our behalf;
                  <br/>(iii)  To comply with our obligations under any applicable laws, rules, regulations, guidelines, notices or directions, including any obligations related to tax matters (such as identification, tax reporting and tax audit) and any obligations related to anti-money laundering and countering the financing of terrorism legislation or regulations;
                  <br/>(iv)   Responding to any request for information made by any governmental or other regulatory body, any exchange or self-regulatory organisation;
                  <br/>(v) To manage your account, maintain your personal profile and personalize your experience on our website;
                  <br/>(vi)   To communicate with you when necessary or appropriately in relation to the services being provided to you or the services being provided to us;
                  <br/>(vii)  To access applications or contracts for our products and services, including to ascertain whether you meet the applicable suitability standards imposed by the jurisdiction of your residence and any laws, rules, regulations, guidelines, notices or directions that apply to NovaLux, and otherwise ascertain whether we determine that you are suitable to enter into any agreement with us;
                  <br/>(viii) To perform analytics (including market research, trend analysis and financial analysis);
                  <br/>(ix)   To update you of products and services which may be relevant to you; and
                  <br/>(x) To review and improve the information provided on our website to ensure it is user friendly and to prevent any potential disruptions. Our entitlement to process your personal data arises from:
                  Our entitlement to process your personal data arises from:
                  <br/>(a) Our need to perform contractual obligations under the agreement which you have entered into or are entering into with us, or will be done at your request prior to entering into that agreement and to exercise our rights in connection with managing your account and providing the products and services to you;
                  <br/>(b) The consent obtained from you;
                  <br/>(c) Our need to discharge legal and regulatory obligations;
                  <br/>(d) Our need to establish, exercise or defend our legal rights, or for the purposes of legal proceedings;
                  <br/>(e) Our legitimate interests to effectively and efficiently administer and manage the operation of our business; ensuring compliance with all legal and regulatory obligations and industry standards and preventing fraud, maintaining compliance with internal policies and procedures, and ensuring the security of our information systems.
               </p>
               <p>5. Disclosure: We may share your personal data within the NovaLux group for the purposes described above. We may also share your personal data with third parties outside of the NovaLux group for the following purposes:
                  <br/>(i) To our business partners who are contractually obliged to comply with appropriate data protection laws and regulations;
                  <br/>(ii)   To the extent required by any applicable laws, rules or regulations, or for the purposes of assessing compliance with applicable laws, rules and regulations;
                  <br/>(iii)  If we sell any of our business or assets, in which case we may disclose your personal data to the prospective buyer for due diligence purposes;
                  <br/>(iv)   To third party agents and contractors for the purposes of providing services to us, including without limitation, external legal counsels, auditors, professional advisors and IT service providers, where such third parties are subject to confidentiality obligations and provided that they use your personal data only as described in this Privacy Policy; and 
                  <br/>(v) To any organisation at your request or any person acting on your behalf (including your agents, advisors and brokers). No personal data will be shared with unaffiliated third parties for their marketing purposes.
               </p>
               <p>6. International Transfers: Personal data may be transferred internationally for the purposes described in this Privacy Policy and as otherwise required or permitted by applicable law. The protections which apply to international transfers of personal data are further provided herein and will apply regardless of the international transfer or processing of such information.</p>
               <p>Where we are subject to the Personal Data Protection Act 2012 (“PDPA”) in respect of our processing of your personal data and if we transfer your personal data outside Singapore, we will take all reasonable steps to ensure that:
               <br/>(i) The recipient agrees to protect personal data at a standard that is at least comparable to the PDPA; and
               <br/>(ii)   Any other transfer will otherwise be in accordance with the PDPA.
               </p>
               <p>7. Retention: The extent of time with which we will retain personal data will vary and will be determined by the following criteria:
               <br/>(i) The purpose for which we are using it (NovaLux will need to keep the personal data for as long as necessary for that purpose); and
               <br/>(ii)   To the extent that we are permitted by law to retain it for a longer period of time (in which case we will retain it for the period permitted by law) or to the extent that we may need to in order to establish or defend our legal rights or for the purpose of legal proceedings.</p>
               <p>8. Rights: To the extent that you are entitled to do so under applicable law:
               <br/>(i) You have a right to obtain information on, and access to, the personal data that we process about you and to request the correction of any error or inaccuracy in relation to such personal data; and
               <br/>(ii)   You also have the right to:
                  <br/>(a) Withdraw your consent to our processing of your personal data at any time, provided that we may still be entitled to process your personal data if we can rely on other legal grounds for doing so;
                  <br/>(b) In certain circumstances and with respect to data you have provided to us, receive any personal data which we process about you on the basis of your consent (as opposed to any other legal grounds) in a structured, commonly used and machine-readable format and/or request that we transmit such data where this is technically feasible;
                  <br/>(c) Request that we rectify your personal data if it is inaccurate or incomplete;
                  <br/>(d) Request that we erase your personal data in certain circumstances, such as where:
                  <br/>(I) It is no longer necessary for us to retain your personal data for the purposes for which it was collected and intended to be processed;
                  <br/>(II)   We are only entitled to process your personal data with your consent, and you withdraw your consent, and where there is no other legal ground for the retention or processing of such data; or
                  <br/>(III)  You object to our processing of your personal data for our legitimate interests, and our legitimate interests do not override your own interests, rights and freedom.
               </p>
               <p>9. Security: NovaLux maintains appropriate technical and organizational security measures to ensure a level of security appropriate to the risk. Given the nature of information security, there is no guarantee that such measures will always prevent a leak of information and/or personal data. NovaLux may be prone to information security and related risks resulting from breaches in cybersecurity, which can result from deliberate attacks such as hacking or other unintentional events, notwithstanding reasonable steps having been taken by NovaLux to prevent or repel them. NovaLux shall not be responsible for the security of your information or personal data and makes no warranty of any kind regarding the security of this website or your information or personal data. If the security of personal data is compromised, the affected individuals will receive notice of the data security breach where required by and consistent with applicable law.</p>
               <p>10. Changes: This Privacy Policy may be updated periodically to reflect changes in NovaLux’s policies and applicable laws and regulations. We suggest that you periodically review this Privacy Policy for updates.</p>
               <p>11. Contact: If you have any questions, requests or concerns about your personal data, or wish to amend, or obtain more information on your personal data, please contact our Data Protection Officer at [enquiries@novaluxim.com]. In order to process your request, we reserve the right to use personal data previously obtained to verify your identity or take other action that we believe appropriate.</p>



            </div>
            <Line />
         </div>
      )
   }


}

export default PrivacyPolicy
