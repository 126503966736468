import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import {Link} from 'react-router-dom'
import Line from 'components/Line'
import Fade from 'react-reveal/Fade';

import Graphic1 from 'assets/images/team/graphic-1.svg'
// import Team1 from 'assets/images/team/team-1.png'
// import Team2 from 'assets/images/team/team-2.png'
import DavidHong from 'assets/images/team/DavidHong.jpg'
import LeeTeckChong from 'assets/images/team/LeeTeckChong.jpg'
import OoiGeneYan from 'assets/images/team/OoiGeneYan.jpg'
import TanYongSheng from 'assets/images/team/TanYongSheng.jpg'
import AllTeam from 'assets/images/team/Team.jpg'
import Linkedin from 'assets/images/team/linkedin.svg'


class Team extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   componentDidMount() {
      TweenMax.fromTo('#graphic-1', 2, {
         x: 100
      }, {
         x: 0, ease: 'Power3.easeOut', delay: 0.3
      })
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 1;
      },500)
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 0;
      },600)
   }

   render () {

      return (
         <div id="team">

            <div id="header" className="center">
               <img src={Graphic1} id="graphic-1" />
               <Fade bottom>
                  <h1 className="h1">OUR TEAM</h1>
                  <p>Our team strives to transform<br/>challenges into opportunities </p>
               </Fade>
            </div>


            <div id="row-1">
               <div className="team-row">
                  <Fade bottom>
                     <img className="portrait" src={DavidHong} />
                     <div className="team-detail">
                        <h5>David Hong</h5>
                        <h6>Founder and Chief Investment Officer</h6>
                        <hr/>
                        <p>David Hong is the Founder and Chief Investment Officer of NovaLux. An experienced trader and fund manager, David wields over 3 decades of experience in the investment industry. He was previously the Asia Head of Nektar Asset Management and sat on Nektar’s Global Management Committee. He was also a member of the Eastfort Asset Management Board of Directors in Hong Kong. </p>
                        <p>Prior to Nektar, David had an illustrious background trading and managing teams at top-tier financial institutions like Morgan Stanley, UBS, Chase Bank and RBS/ABN. </p>
                        <p>Over his career, David has endeavoured to be the nexus between academic theory and the financial market, challenging legacy systems to find innovative solutions that value adds to investors. </p>
                        <p>David holds an honours degree in Engineering from the National University of Singapore.</p>
                        
                     </div>
                  </Fade>
               </div>
            </div>

            <Line right />

            <Fade bottom cascade>
               <div id="row-2">
                  <div className="columns">
                     <img className="portrait" src={TanYongSheng} />
                     <div className="team-detail">
                        <h5>Tan Yong Sheng</h5>
                        <h6>Chief Executive Officer</h6>
                        <hr/>
                        <p>Tan Yong Sheng is the Chief Executive Officer of NovaLux. Previously, he was the CEO of Eastfort Asset Management, a China-focused fixed income firm.</p>
                        <p>Throughout his professional career, Yong Sheng has held multiple management roles at leading financial institutions such as Barclays Capital, DBS Bank, J.P. Morgan and UBS. Prior to NovaLux, he was appointed the Head of Southeast Asia Investor Solutions at Barclays Capital, managing an AUM of USD 4 billion across different strategies at its peak, as well as Head of Equity Structuring at DBS Bank, where he was responsible for innovating diverse strategies. </p>
                        <p>Yong Sheng graduated with an honours degree in Business Administration (Finance) from the National University of Singapore.</p>
                        
                     </div>
                  </div>

                  <Line className="hide-lg-flex" />
                  <div className="columns">
                     <img className="portrait" src={LeeTeckChong} />
                     <div className="team-detail">
                        <h5>Lee Teck Chong</h5>
                        <h6>Deputy Chief Investment Officer</h6>
                        <hr/>
                        <p>Lee Teck Chong is the Deputy Chief Investment Officer of NovaLux. Prior to joining NovaLux, Teck Chong was the Managing Partner of Oasis Asia Capital, a family office specialising in the trading of Asia equity markets. </p>
                        <p>Formerly, he was also a Portfolio Manager at Nektar Asset Management, where he traded Asian markets based on global macro strategies, as well as the Managing Director and Head of Long Term Interest Rate Products (Taiwan and China) and Head of Taiwan local market trading at BNP Paribas.</p>
                        <p>Teck Chong is a qualified CFA Charterholder, and graduated from Nanyang Technological University of Singapore with an honours degree in Accountancy (Minoring in Banking and Finance). </p>
                        
                     </div>
                  </div>
                  <Line right className="hide-lg-flex" />
                  <div className="columns">
                     <img className="portrait" src={OoiGeneYan} />
                     <div className="team-detail">
                        <h5>Ooi Gene Yan</h5>
                        <h6>Head of Quant and Technology</h6>
                        <hr/>
                        <p>Ooi Gene Yan is the Head of Quant and Technology of NovaLux. Prior to joining NovaLux, he was the Chief Data Scientist and Co-founder at Traceto.io, a decentralised Know Your Customer (KYC) Network, where he was involved heavily in the design of the overall Network and the implementation of transaction monitoring tools. Gene Yan was also formerly at an artificial intelligence and deep learning start-up, Shentilium Technologies, and was responsible in driving the overall technology and product direction of the company.   </p>
                        <p>Gene Yan holds an honours degree in Quantitative Finance from the National University of Singapore. </p>
                        
                     </div>
                  </div>
               </div>
            </Fade>
            <Line className="hide-lg-flex" />

            <div className="center">
               <img src={AllTeam} id="all-team-photo" />
               <p id="all-team-head" >NOVALUX</p>
               <p id="all-team-body">The Team</p>
               <hr className="short-line" />
            </div>

            <Line right className="small-line-2 hide-sm" />
            <Line className="hide-lg" />

            <Fade bottom>
               <div id="bottom">
                  <div className="center">
                     <h1 className="h1" >INTERESTED?</h1>
                     <p>NovaLux is always looking for new talent to expand our team of experts and innovators. Join us today!</p>
                  </div>
                  <Link to="/careers" className="btn"
                  >
                     Learn More
                  </Link>

               </div>
            </Fade>

         </div>
      )
   }


}

export default Team
