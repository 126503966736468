import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter, Switch} from 'react-router-dom'
import { TransitionGroup, Transition } from 'react-transition-group';
import { createBrowserHistory } from 'history';
import { hot } from 'react-hot-loader'
import {TweenLite} from 'gsap'
import { loadReCaptcha } from 'react-recaptcha-v3'

import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Error404 from './pages/Error404'
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import Team from './pages/Team'
import Careers from './pages/Careers'
import Contact from './pages/Contact'
import TermsCondition from './pages/TermsCondition'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TaCPopup from './components/TaCPopup'
import Stars from './components/Stars'



let history = createBrowserHistory();
let historyObject = history.location.pathname;

const completeCall = target => {
   TweenLite.set(target, { clearProps: "position, width, transform" });
};

class App extends Component {

   componentDidMount() {
      loadReCaptcha('6LfKXaQUAAAAAAFrozaX9yuAHazjN4bTTKFyZeCn');
   }

   componentWillMount() {
      this.unlisten = history.listen((location, action) => {
         historyObject = location.pathname;
      });
   }
   componentWillUnmount() {
      this.unlisten();
   }

   render() {
      return (

         <Router>
            <Route
               render={({ location }) => (
                  <div >
                     <Route exact path="/" />
                     <Navbar />

                     <TransitionGroup>
                        <Transition
                           key={location.key}
                           timeout={500}
                           mountOnEnter={true}
                           unmountOnExit={true}

                           onEnter={node => {
                              TweenLite.killTweensOf(node);
                              setTimeout( () => {
                                 document.body.scrollTop = document.documentElement.scrollTop = 0;
                              }, 400)
                              const parent = node.parentNode;
                              const targetWidth = parent.clientWidth - parseFloat(getComputedStyle(node.parentNode).paddingLeft) * 2;
                              TweenLite.set(node, {
                                 x: 150,
                                 autoAlpha: 0,
                                 width: targetWidth,
                                 display: 'none',
                              });
                              TweenLite.to(node, 0.5, {
                                 display: 'block',
                                 autoAlpha: 1,
                                 x: 0,
                                 onComplete: completeCall,
                                 onCompleteParams: [node],
                                 delay: 0.5
                              });
                              setTimeout( () => {
                                 TweenLite.fromTo('#stars', 0.3, {opacity: 0}, {
                                    opacity: 1,
                                 })
                              },1000)
                           }}
                           onExit={node => {
                              TweenLite.killTweensOf(node);
                              const parent = node.parentNode;
                              const targetWidth = parent.clientWidth - parseFloat(getComputedStyle(node.parentNode).paddingLeft) * 2;
                              TweenLite.set(node, {
                                 width: targetWidth
                              });
                              TweenLite.to(node, 0.4, {
                                 opacity: 0,
                                 x: -100
                              });
                              TweenLite.to('#stars', 0.3, {
                                 opacity: 0,
                              })
                           }}>
                           <div id="parent">
                              <Stars />

                              <Switch location={location}>
                                 <Route path="/" component={Home} exact />
                                 <Route path="/about-us" component={AboutUs}/>
                                 <Route path="/team" component={Team} />
                                 <Route path="/careers" component={Careers} />
                                 <Route path="/contact" component={Contact} />
                                 <Route path="/terms-and-conditions" component={TermsCondition} />
                                 <Route path="/privacy-policy" component={PrivacyPolicy} />

                                 <Route component={Error404} />
                              </Switch>
                              <TaCPopup />
                              <Footer />
                           </div>
                        </Transition>


                     </TransitionGroup>
                  </div>
               )}
            />
         </Router>

      );
   }
}

export default hot(module)(App);
