import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import axios from 'axios'
import Line from 'components/Line'
import Dropdown from 'react-dropdown'

import { ReCaptcha } from 'react-recaptcha-v3'
import Fade from 'react-reveal/Fade';

import Phone from 'assets/images/footer/phone.svg'
import Mail from 'assets/images/footer/mail.svg'
import Pin from 'assets/images/footer/pin.svg'
import Close from 'assets/images/career/close.svg'
import SuccessIcon from 'assets/images/contact/success.svg'
import ErrorIcon from 'assets/images/contact/error.svg'


class Contact extends Component {

   constructor() {
      super()
      this.state = {
         message: {
            type: 'general',
            subject: '',
            name: '',
            email: '',
            message: '',
            recaptchaToken: '',
         },
         required: {
            subject: false,
            name: false,
            email: false,
            message: false,
         },
         error_message: '',
         message_height: 30
      }
   }
   interval
   componentDidMount() {
      //loadReCaptcha("6LfgT6QUAAAAAHjwwhDLBEdRhDEvi5Dbt3lJjrJ9");
      //this.recaptchaRef.execute()
      this.interval = setInterval( ()=> {
         //this.recaptchaRef.execute()
      },10000)
      TweenMax.fromTo('#message', 2.5, {
         x: 150,
      }, {
         x: 0, ease: 'Power2.easeOut',
      })
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 1;
      },500)
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 0;
      },600)
   }
   componentWillUnmount() {
      clearInterval(this.interval)
   }
   onChange = (e, f) => {
      let {message} = this.state
      message[f] = e.target.value
      this.setState({ message })
   }
   onSelect = (e) => {
      let {message} = this.state
      message.type = e.value
      this.setState({ message })


   }
   onSend = async(e) => {
      e.preventDefault()
      let {message, required} = this.state
      let error = false
      if(message.subject == '') {
         required.subject = true
         error = true
      }else{
         required.subject = false
      }
      if(message.name == '') {
         required.name = true
         error = true
      }else{
         required.name = false
      }
      if(message.email == '') {
         required.email = true
         error = true
      }else{
         required.email = false
      }
      if(message.message == '') {
         required.message = true
         error = true
      }else{
         required.message = false
      }
      if(error) {
         this.setState({ required })
         return
      }

      const res = await axios.post('https://novaluxim.com/wp/wp-json/novalux/contact', message)
      let {data} = res
      data = JSON.parse(data)
      // let data = {success: true}
      if(data.success) {
         TweenMax.to("#success-modal", 0.3, {
            opacity: 1,
            display: 'block',
         })
      }else{
         this.setState({ error_message: data.message })
         TweenMax.to("#error-modal", 0.3, {
            opacity: 1,
            display: 'block',
         })
      }
      TweenMax.to(`#overlay`, 0.2, {
         opacity: 1,
         display: 'block'
      })
      //this.recaptchaRef.execute()
      this.setState({ message: {
         type: 'general',
         subject: '',
         name: '',
         email: '',
         message: '',
         recaptchaToken: '',
      } })
   }

   verifyCallback = async(recaptchaToken) => {
      //console.log(recaptchaToken, "<= your recaptcha token")
      this.setState({ message: {...this.state.message, recaptchaToken} })
   }
   onCloseModal = () => {
      TweenMax.to("#success-modal, #error-modal", 0.3, {
         opacity: 0,
         display: 'none',
      })
      TweenMax.to(`#overlay`, 0.2, {
         opacity: 0,
         display: 'none'
      })
   }

   onChangeMessage = (e) => {
      const {message} = this.state
      this.setState({ message: {...message, message: e.target.value}, message_height: this.message.scrollHeight - 20 })
   }

   render () {
      const {message, required, message_height} = this.state

      return (
         <div id="contact">

            <Fade bottom cascade>
               <div id="contact-us">
                  <h1 className="h1">CONTACT US</h1>
                  <p id="subtitle">Get in touch with us for fund enquiries <br className="hide-lg"/>and career opportunities.</p>
                  <div className="contacts">
                     <img src={Phone} alt="phone icon"  id="phone" />
                     <p ><a href="tel:+6563292217">+65 <span className="regular">6329 2217</span></a></p>
                  </div>
                  <div className="contacts">
                     <img src={Mail} alt="mail icon"  id="mail" />
                     <p className="regular"><a href="mailto:enquiries@novaluxim.com" >enquiries@novaluxim.com</a></p>
                  </div>
                  <div className="contacts">
                     <img src={Pin} alt="pin icon"  id="pin" />
                     <p><a href='https://goo.gl/maps/DPhf1B3xHWE2' target="_blank">6 Battery Road, #12-01,<br/>Singapore 049909</a></p>
                  </div>
                  <Line right className="hide-sm-flex" />
                  <br className="hide-lg" />
                  <Line className="hide-lg-flex" />
               </div>
            </Fade>

            <div id="message">
               <h2 className="h2">SEND US A MESSAGE</h2>

               <form>

                  <div className="input">
                     <p className="error"></p>
                     <Dropdown options={options} onChange={this.onSelect} value={message.type} />
                  </div>
                  <div className="input">
                     <p className="error">{required.subject && `Required Field*` }</p>
                     <input placeholder="Subject" value={message.subject} className={required.subject ? 'error-input' : '' }
                        onChange={(e)=>this.onChange(e, 'subject')}
                        />
                  </div>
                  <div className="input">
                     <p className="error">{required.name && `Required Field*` }</p>
                     <input placeholder="Your Name" value={message.name} className={required.name ? 'error-input' : '' }
                        onChange={(e)=>this.onChange(e, 'name')}
                        />
                  </div>
                  <div className="input">
                     <p className="error">{required.email && `Required Field*` }</p>
                     <input placeholder="Your Email" value={message.email} className={required.email ? 'error-input' : '' }
                        onChange={(e)=>this.onChange(e, 'email')}
                        />
                  </div>
                  <div className="input full">
                     <p className="error">{required.message && `Required Field*` }</p>
                     <textarea placeholder="Your Message" rows={1} value={message.message} className={required.message ? 'error-input' : '' }
                        onChange={this.onChangeMessage} style={{ height: `${message_height}px` }} ref={(i)=>this.message = i} id="message-body" >

                     </textarea>
                  </div>


                  <ReCaptcha
                     //ref={i=>this.recaptchaRef=i}
                     sitekey="6LfKXaQUAAAAAAFrozaX9yuAHazjN4bTTKFyZeCn"
                     action='action_name'
                     verifyCallback={this.verifyCallback}
                     />

                  <button className="btn" onClick={this.onSend} disabled={!message.recaptchaToken} >
                     Send
                  </button>

               </form>

            </div>


            <div id="overlay" onClick={this.onCloseModal} />

            <div id="success-modal" className="modal">
               <img id="close" src={Close} width="20px" onClick={this.onCloseModal} />
               <div className="center modal-content">
                  <img id="tick" src={SuccessIcon} width="45px" />
                  <h2 className="h2">SUCCESS!</h2>
                  <p>Your message has been <br/>sent successfully</p>
               </div>
            </div>

            <div id="error-modal" className="modal">
               <img id="close" src={Close} width="20px" onClick={this.onCloseModal} />
               <div className="center modal-content">
                  <img id="tick" src={ErrorIcon} width="45px" />
                  <h2 className="h2">ERROR!</h2>
                  <p>{this.state.error_message}</p>
               </div>
            </div>

         </div>
      )
   }


}

const options = [
   { value: 'general', label: 'General Enquiry' },
   { value: 'investment', label: 'Investment Related' },
   { value: 'recruitment', label: 'Recruitment' },
   { value: 'press', label: 'Press / Media' },
]

export default Contact
