import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import Axios from 'axios'
import Line from 'components/Line'


class TermsCondition extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   componentDidMount() {

   }

   render () {

      return (
         <div id="terms">
            <div id="terms-inner">
               <h1 className="h2">TERMS & CONDITIONS</h1>

               <p>1. Website for informational purposes only: This website is for informational purposes only and does not constitute a solicitation for investment in the company described herein, nor does it constitute an offer for sale of shares issued by the company and its affiliates. Any offer can only be made by and upon the terms of the relevant offering documents, together with the relevant subscription documents, all of which must be read and understood in their entirety, and only in jurisdictions where such offer is in compliance with relevant laws and regulatory requirements.</p>
               <p>“NovaLux”, wherever used in this website, means “Nova Management Holdings Pte. Ltd.” and its affiliates, their officers, directors, partners and employees. “This website” means the website of NovaLux located at [novaluxim.com].</p>
               <p>This website has been prepared for the purpose of providing general information only, without taking account of any particular investor’s objectives, financial situation or needs and does not amount to any investment recommendation. An investor should, before making any investment decision, consider the appropriateness of the information in this website and seek professional advice, having regard to the investor’s objectives, financial situation and needs. In all cases, anyone intending to rely on or use the information contained in this website should independently verify the accuracy, completeness, reliability and suitability of such information. </p>
               <p>Please read the Terms of Use carefully before using this website. NovaLux reserves the right to change, modify, add or remove portions of the Terms of Use at any time for any reason and in our sole discretion. We suggest that you periodically review the Terms of Use for amendments. The Terms of Use will indicate the date they were last amended. By accessing or using this website, you hereby fully accept and agree to abide by the Terms of Use as modified from time to time. The Terms of Use is a binding agreement between you and NovaLux, and governs your access to this website, which includes any information, data and other content made available on or through (including any requests you made through) this website. If you do not accept the terms and conditions stated in these Terms of Use, you are not authorized to use this website and should not use this website. </p>
               <p>2. Representations by the User: By clicking the “I AGREE” button, you are deemed to be representing and warranting that (1) you have read and understood the information contained in the Terms of Use; (2) the applicable laws and regulations of your jurisdiction allows you to access the information on this website; (3) you are duly authorized to access this website for the purpose of acquiring information; and (4) you and any person or entity you represent has initiated the communications with NovaLux or its representatives prior to such unsolicited initiation of communications. </p>
               <p>3. Not intended for certain jurisdictions: The information on this website is not intended for persons located or resident in jurisdictions where the distribution of such information is restricted or unauthorized. No action has been taken to register or qualify NovaLux or any of its associated funds for distribution or offering in any such jurisdiction.</p>
               <p>4. Information contained herein & other websites: To the best of its knowledge and belied, NovaLux considers the information contained here to be accurate as at the date of publication. No representation or warranty is given, whether expressed or implied on the accuracy, adequacy or completeness of any information provided in this website or by any third parties.</p>
               <p>Simulated and past and projected performance may not necessarily be indicative of future results. Figures may be taken from sources that are believed to be reliable (but may not necessarily have been independently verified), and such figures should not be relied upon in making investment decisions. There is a risk of loss when investing in funds managed by NovaLux. The materials in this website could include technical inaccuracies and errors or could become inaccurate or erroneous as a result of developments after the date of publication. </p>
               <p>NovaLux does not undertake the responsibility to maintain the currency of such information. Any links to other websites contained within this website are for convenience of the user only and do not constitute an endorsement by NovaLux of these websites. NovaLux is not responsible for the content of other websites referenced in this website. Neither NovaLux nor its affiliates or their respective shareholders, directors, officers or employees assume any liability in respect of any errors or omissions on this website, or any and all responsibility for any direct or consequential loss or damage of any kind resulting directly or indirectly from the use of this website. Unless otherwise agreed with NovaLux, any use, disclosure, reproduction, modification or distribution of the contents of this website, or any part thereof, is strictly prohibited. NovaLux expressly disclaims any liability, whether in contract or tort, strict liability or otherwise, for any direct, indirect, incidental, consequential, punitive or special damages arising out of, or in any way, connected with, your access to or use of this website.</p>
               <p>5. Licenses: NovaLux Investment Management Pte. Ltd. holds a capital markets services license for the provision of fund management services to eligible investors and is an exempt financial advisor pursuant to paragraph 23(1)(d) of the Financial Advisers Act (“FAA”). Accordingly, this website and its contents are permitted only for the use of persons who are “institutional investors” or “accredited investors”, each within the meaning provided in the Singapore Securities and Futures Act (Cap. 289); or the equivalent class of “accredited investor” under the laws of the country or territory of such person. As an “institutional investor” and/or “accredited investor”, certain disclosure requirements under the FAA in relation to the contents of this website would not apply to you as a recipient. The products and services described in this website would not apply to you as a recipient. The products and services described in this website are available only to such categories of persons. None of the contents of this website have been approved or endorsed by the MAS or any other regulatory authority. </p>

            </div>
            <Line />
         </div>
      )
   }


}

export default TermsCondition
