import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import Axios from 'axios'
import Line from 'components/Line'
import {Link} from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'
import Fade from 'react-reveal/Fade';

import Graphic1 from 'assets/images/home/graphic-1.svg'
import Graphic2 from 'assets/images/home/graphic-2.svg'
import Graphic3 from 'assets/images/home/graphic-3.svg'


class Home extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   componentDidMount() {
      TweenMax.fromTo('#graphic-1', 2, {
         x: 100
      }, {
         x: 0, ease: 'Power3.easeOut', delay: 0.5
      })
      TweenMax.set('#graphic-2', { x: -100 })
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 1;
      },500)
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 0;
      },600)
   }

   onVSGraphic2 = (isVisible) => {
      if(isVisible){
         TweenMax.to('#graphic-2', 2, {
            x: 0, ease: 'Power3.easeOut',
         })
      }
   }

   render () {

      return (
         <div id="home">

            <div className="segments segments-1">
               <div className="texts">
                  <Fade bottom>
                     <h1 className="h1">TIMELESS<br/>PRINCIPLES</h1>
                     <p >Our extensive market experience places us in a prime position to optimise and streamline investment philosophies, techniques and processes - delivering positive returns.</p>
                  </Fade>
               </div>
               <div className="graphics" id="graphic-1">
                  <img src={Graphic1} width="90%" />
               </div>
            </div>

            <Line className="small-line" />

            <div className="segments segments-2">

               <div className="graphics" id="graphic-2">
                  <img src={Graphic2} width="80%" />
               </div>

               <VisibilitySensor onChange={this.onVSGraphic2} >

                  <div className="texts">
                     <Fade bottom>
                        <h1 className="h1">ADAPTIVE<br/>SYSTEMATIC<br/>STRATEGY</h1>
                        <p >With our systematic, model based investment technology, we are in place to achieve strong, sustainable, all-weather, and risk-adjusted absolute returns.</p>
                     </Fade>
                  </div>
               </VisibilitySensor>

            </div>

            <Line right />

            <Fade bottom>
               <div id="investment">
                  <div className="center">
                     <h1 className="h1" >INVESTMENT FUND <br className="hide-sm"/>OF <br className="hide-lg" />THE FUTURE</h1>
                  </div>
                  <Link to="/about-us" className="btn"
                  >
                     Learn More
                  </Link>

                  <Line className="hide-sm" />
               </div>
            </Fade>

         </div>
      )
   }


}

export default Home
