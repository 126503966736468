import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import Logo from 'assets/images/logo.svg'
import {Link} from 'react-router-dom'
import Stars from './Stars'

import BurgerIcon from 'assets/images/burger.svg'
import LogoIcon from 'assets/images/logo-icon.svg'
import Close from 'assets/images/career/close.svg'

class Navbar extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   componentDidMount() {

   }

   openBurger = () => {
      TweenMax.set('body', {overflow: 'hidden'})
      TweenMax.to("#nav-overlay", 0.2, {
         opacity: 1,
         display: 'block'
      })
      TweenMax.to('#nav-block', 0.3,{
         width: '70%',
         opacity: 1,
         display: 'block'
      })
   }

   closeMobileNav = () => {
      TweenMax.set('body', {overflow: 'auto'})
      TweenMax.to("#nav-overlay", 0.2, {
         opacity: 0,
         display: 'none'
      })
      TweenMax.to("#nav-block", 0.3, {
         width: '20%',
         opacity: 0,
         display: 'none'
      })
   }

   render () {
      let pathname = window.location.pathname;
      let paths = pathname.split("/")
      pathname = paths[1]
      return (
         <div id="navbar">
            <Link to="/" ><img src={Logo} id="logo" /></Link>
            <div id="links-desktop">
               <Link to='/' className={pathname === '' ? 'menu-active' : ''}  >Home</Link>
               <Link to='/about-us' className={pathname === 'about-us' ? 'menu-active' : ''}  >About us</Link>
               <Link to='/team' className={pathname === 'team' ? 'menu-active' : ''}  >Team</Link>
               <Link to='/careers' className={pathname === 'careers' ? 'menu-active' : ''}  >Careers</Link>
               <Link to='/contact' className={pathname === 'contact' ? 'menu-active' : ''}  >Contact</Link>
            </div>

            <div id="links-mobile">
               <div id="burger" onClick={this.openBurger} >
                  <img src={BurgerIcon} width="20px" />
               </div>
               <div id="nav-block">
                  <Stars count={50} />
                  <div id="close" onClick={this.closeMobileNav} >
                     <img  src={Close} width="20px" />
                  </div>
                  <img id="logo-icon" src={LogoIcon} width="50px" />
                  <Link to='/' onClick={this.closeMobileNav} >HOME</Link>
                  <Link to='/about-us' onClick={this.closeMobileNav} >ABOUT US</Link>
                  <Link to='/team' onClick={this.closeMobileNav} >TEAM</Link>
                  <Link to='/careers' onClick={this.closeMobileNav} >CAREERS</Link>
                  <Link to='/contact' onClick={this.closeMobileNav} >CONTACT</Link>
               </div>
               <div id="nav-overlay" className="overlay" onClick={this.closeMobileNav} />
            </div>
         </div>
      )
   }


}

export default Navbar
