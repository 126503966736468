import React, { Component } from 'react';

import LineLeft from 'assets/images/line-left.svg'
import LineRight from 'assets/images/line-right.svg'

class Line extends Component {

   render () {
      const {right, className, style} = this.props
      if(right) {
         return (
            <div className={`lines ${className}`} style={{display: 'flex', justifyContent: 'flex-end'}}>
               <img src={LineRight} width="100px" />
            </div>
         )
      }else{
         return (
            <div className={`lines ${className}`}  >
               <img src={LineLeft} width="100px"  />
            </div>
         )
      }

   }


}

export default Line
