import React, { Component } from 'react';
import {TweenMax} from 'gsap'


class TaCPopup extends Component {

   constructor() {
      super()
      this.state = {
         show: false
      }
   }

   componentDidMount() {
      let tac_popup = localStorage.getItem('tac_popup')
      console.log(getCookie('tac_popup'))
      if(!tac_popup){
         this.setState({ show: true })
         TweenMax.set('body', { overflow: 'hidden', position: 'fixed' })
      }
   }

   onDisagree = () => {
      window.location.href = "https://www.google.com"
   }

   onAgree = () => {
      localStorage.setItem('tac_popup', true)
      document.cookie = "tac_popup=true"
      TweenMax.set('body', { overflow: 'auto', position: 'static' })
      TweenMax.to("#tacpopup", 0.3, {
         opacity: 0,
         display: 'none',
      })
      setTimeout( () => {
         this.setState({ show: false })
      }, 300)
   }


   render () {
      const {show} = this.state
      if(show){
         return (
            <div id="tacpopup">
               <div id="overlay" />
               <div id="tacpopup-content">
                  <h5>TERMS & CONDITIONS</h5>
                  <div id="text">
                     <p>NovaLux Investment Management Pte. Ltd. (NovaLux) is an investment management firm. It advises certain private investment funds and does not provide financial or investment advice or similar services to most other investors. This website is for informational purposes only and does not constitute a solicitation for investment in any form, in the company described herein or any of its affiliates. This website does not contain any information that an investor should consider or evaluate to make any potential investment. Offering materials relating to investments in any entities managed by NovaLux are not available to the general public.</p>
                     <p>Please read the Terms & Conditions carefully before using this website. NovaLux reserves the right to modify any part of the Terms & Conditions at any time for any reason in our sole discretion. We suggest that you periodically review the Terms & Conditions for amendments. By accessing or using this website, you hereby fully accept and agree to abide by the Terms & Conditions as modified from time to time. By clicking the “AGREE” button below, you are agreeing to the Terms & Conditions, which are a binding agreement between you and NovaLux, and governs your access and use of this website, which includes any information, data and any other content made available on or through (including any enquiries made by you through) this website. If you do not accept the Terms & Conditions, you are not an authorized user of this website and should not access or use this website. </p>
                  </div>
                  <div id="button" >
                     <button id="disagree" className="btn" onClick={this.onDisagree}>
                        Disagree
                     </button>

                     <button id="agree" className="btn" onClick={this.onAgree}>
                        Agree
                     </button>
                  </div>

               </div>

            </div>
         )
      }else {
         return null
      }
   }


}
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default TaCPopup
