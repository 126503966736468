import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import {Link} from 'react-router-dom'

import Logo from 'assets/images/logo.svg'
import LogoSquare from 'assets/images/logo-square.svg'
import Phone from 'assets/images/footer/phone.svg'
import Mail from 'assets/images/footer/mail.svg'
import Pin from 'assets/images/footer/pin.svg'

class Footer extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   componentDidMount() {
//Copyright &copy; {(new Date()).getFullYear()}
   }

   render () {
      let pathname = window.location.pathname;
      let paths = pathname.split("/")
      pathname = paths[1]
      return (
         <div id="footer">

            <div >
               <div id="footer-1">
                  <img src={Logo} id="logo" />
                  <p>Our extensive market experience places us in a prime position to optimise and streamline investment philosophies, techniques and processes - delivering positive returns.</p>
               </div>
               <div id="footer-1-mobile">
                  <img src={LogoSquare} id="logo-square" />
               </div>

               <div id="footer-2">
                  <div id="footer-2-inner">
                     <h5 className="h5">GET IN TOUCH</h5>
                     <div className="contacts">
                        <img src={Phone} alt="phone icon" id="phone" />
                        <p><a href='tel:+6563292217'>+65 6329 2217</a></p>
                     </div>
                     <div className="contacts">
                        <img src={Mail} alt="mail icon" id="mail" />
                        <p><a href="mailto:enquiries@novaluxim.com" >enquiries@novaluxim.com</a></p>
                     </div>
                     <div className="contacts">
                        <img src={Pin} alt="pin icon" id="pin"  />
                        <p><a href='https://goo.gl/maps/DPhf1B3xHWE2' target="_blank">6 Battery Road, #12-01,<br/>Singapore 049909</a></p>
                     </div>
                  </div>
               </div>

               <div id="footer-3">
                  <Link to='/' >HOME</Link>
                  <Link to='/about-us' >ABOUT US</Link>
                  <Link to='/team' >TEAM</Link>
                  <Link to='/careers' >CAREERS</Link>
                  <Link to='/contact' >CONTACT</Link>
               </div>

            </div>

            <hr id="footer-seperator-mobile" />

            <div id="footer-bottom">
            
               <p>Copyright © 2019 NovaLux. <br className="hide-lg" />All rights reserved.</p>
               <p id="footer-links">
                  <Link to='privacy-policy'>Privacy Policy</Link>
                  |
                  <Link to='terms-and-conditions'>Terms & Conditions</Link>
               </p>
            </div>

         </div>
      )
   }


}

export default Footer
