import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import Line from 'components/Line'

import { ReCaptcha } from 'react-recaptcha-v3'
import Fade from 'react-reveal/Fade';

import ArrowDown from 'assets/images/career/arrow-down.svg'
import Close from 'assets/images/career/close.svg'
import FileIcon from 'assets/images/career/file.svg'
import CrossIcon from 'assets/images/contact/error.svg'
import SuccessIcon from 'assets/images/contact/success.svg'
import ErrorIcon from 'assets/images/contact/error.svg'

class Careers extends Component {

   constructor() {
      super()
      this.state = {
         careers: null,
         career_apply: null,
         loading_btn: false,
         error_message: '',
         apply: {
            files: [],
            name: '',
            email: '',
            phone: '',
            company: '',
            additional: '',
            recaptchaToken: '',
         },
         required: {
            files: false,
            name: false,
            email: false,
            phone: false,
         },
         additional_height: 30,

      }
   }

   interval
   async componentDidMount() {
      //this.recaptchaRef.execute()
      //loadReCaptcha("6LfgT6QUAAAAAHjwwhDLBEdRhDEvi5Dbt3lJjrJ9");
      setTimeout( async() => {
         const careers_res = await axios.get(`https://novaluxim.com/wp/wp-json/wp/v2/careers`)
         const careers = careers_res.data
         this.setState({ careers })
      }, 800)
      this.interval = setInterval( ()=> {
         //this.recaptchaRef.execute()
      },10000)
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 1;
      },500)
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 0;
      },600)

   }
   componentWillUnmount() {
      clearInterval(this.interval)
   }

   onExpand = (i) => {
      let expand = document.getElementById(`careers-expand-${i}`)
      if(expand.style.display == 'block') {
         TweenMax.to(`#arrow-${i}`, 0.3, {rotation: '0deg'})
         TweenMax.to(`#careers-expand-${i}`, 0.3, {
            display: 'none',
            opacity: 0,
         })
      }else{
         TweenMax.to(`#arrow-${i}`, 0.3, {rotation: '180deg'})
         TweenMax.to(`#careers-expand-${i}`, 0.3, {
            display: 'block',
            opacity: 1,
         })
      }
   }
   verifyCallback = (recaptchaToken) => {
      // console.log(recaptchaToken, "<= your recaptcha token")
      this.setState({ apply: {...this.state.apply, recaptchaToken} })
   }




   apply = (e, i) => {
      e.stopPropagation()
      const {careers} = this.state
      const career_apply = careers[i]
      const apply = { files: [], name: '', email: '', phone: '', company: '', additional: '', recaptchaToken: this.state.apply.recaptchaToken }
      const required = { files: false, name: false, email: false, phone: false }
      this.setState({ career_apply, apply, required, additional_height: 30  })
      let modal = document.getElementById('modal')

      TweenMax.set('body', {overflow: 'hidden'})
      TweenMax.set('#navbar', {display: 'none'})

      TweenMax.fromTo(`#modal`, 0.3, {
         top: '+=50px',
      }, {
         top: '100px',
         opacity: 1,
         display: 'block',
      })
      TweenMax.to(`#overlay`, 0.2, {
         opacity: 1,
         display: 'block'
      })

   }

   onChange = (e, f) => {
      let {apply} = this.state
      apply[f] = e.target.value
      this.setState({ apply })
   }
   onDrop = (files) => {
      let {apply} = this.state
      // console.log(apply)
      apply.files.push(...files)
      // console.log(apply.files)
      this.setState({ apply })
   }






   onApply = async(e) => {
      e.preventDefault()
      let {apply, required, career_apply} = this.state
      let error = false

      if(apply.files.length == 0) {
         required.file = true
         error = true
      }else{
         required.files = false
      }
      if(apply.name == '') {
         required.name = true
         error = true
      }else{
         required.name = false
      }
      if(apply.email == '') {
         required.email = true
         error = true
      }else{
         required.email = false
      }
      if(apply.phone == '') {
         required.phone = true
         error = true
      }else{
         required.phone = false
      }
      this.setState({ required })
      if(error) {
         return
      }
      this.setState({ loading_btn: true })
      let _files = []
      for (const file of apply.files) {
         let form = new FormData()
         form.append('file', file)
         const res = await axios.post('https://novaluxim.com/wp/wp-json/novalux/media', form)
         if(res.data.code == '200'){
            _files.push(res.data.id)
         }else{
            this.setState({ error_message: `Uploading the File - ${file.name} is failed.` })
            TweenMax.to(`#modal`, 0.3, {
               top: '+=50px',
               opacity: 0,
               display: 'none',
            })
            setTimeout( () => {
               TweenMax.to("#error-modal", 0.3, {
                  opacity: 1,
                  display: 'block',
               })
            }, 400)
            this.setState({ loading_btn: false })
            //this.recaptchaRef.execute()
            return
         }
      }
      const res = await axios.post('https://novaluxim.com/wp/wp-json/novalux/apply', {...apply, post_id: career_apply.id, files: _files })
      let {data} = res
      if(data.code == '200') {
         TweenMax.to(`#modal`, 0.3, {
            top: '+=50px',
            opacity: 0,
            display: 'none',
         })
         setTimeout( () => {
            TweenMax.to("#success-modal", 0.3, {
               opacity: 1,
               display: 'block',
            })
         }, 400)
      }else{
         TweenMax.to(`#modal`, 0.3, {
            top: '+=50px',
            opacity: 0,
            display: 'none',
         })
         setTimeout( () => {
            TweenMax.to("#error-modal", 0.3, {
               opacity: 1,
               display: 'block',
            })
         }, 400)

      }
      //this.recaptchaRef.execute()
      this.setState({ loading_btn: false })
   }

   onRemoveFile = (i) => {
      let {apply} = this.state
      apply.files.splice(i, 1)
      this.setState({ apply })
   }

   onCloseModal = () => {
      TweenMax.set('#navbar', {display: 'block'})
      TweenMax.set('body', {overflow: 'auto'})
      TweenMax.to(`#modal`, 0.3, {
         top: '+=50px',
         opacity: 0,
         display: 'none',
      })
      TweenMax.to("#success-modal, #error-modal", 0.3, {
         opacity: 0,
         display: 'none',
      })
      TweenMax.to(`#overlay`, 0.2, {
         opacity: 0,
         display: 'none'
      })
   }

   onChangeAdditional = (e) => {
      const {apply} = this.state
      this.setState({ apply: {...apply, additional: e.target.value}, additional_height: this.additional.scrollHeight - 20 })
   }

   render () {
      const {careers, career_apply, apply, required, loading_btn, additional_height} = this.state

      return (
         <div id="career-parent" >
            <div id="career">
               <h1 className="h2">CURRENT JOB OPENINGS</h1>
               <p id="subtitle">NovaLux is always on the lookout for driven and talented team players.</p>

               {careers ?
                  <div id="careers">
                     <Fade bottom cascade>
                        {careers.length > 0 ?
                           <div>
                              {careers.map( (career, i) => (
                                 <div key={i} className="careers"  >
                                    <div id={`careers-${i}`} className="careers-fixed" onClick={()=>this.onExpand(i)}  >
                                       <div className="left">
                                          <p className="small category">{career.acf.category}</p>
                                          <h3 className="title">{career.title.rendered}</h3>
                                          <p className="small subtitle">{career.acf.subtitle}</p>
                                       </div>
                                       <div className="right">
                                          <button className="btn apply hide-sm-inline" onClick={(e)=>this.apply(e,i)}>Apply</button>
                                          <span id={`arrow-${i}`} className="arrow"  ><img src={ArrowDown} width="20px" /></span>
                                       </div>
                                    </div>
                                    <div id={`careers-expand-${i}`} className="careers-expand" >
                                       <hr/>
                                       <div className="description" >
                                          <h4>JOB DESCRIPTION</h4>
                                          <div className="html" dangerouslySetInnerHTML={{__html: career.acf.job_description}} />
                                       </div>
                                       <div className="responsibilities" >
                                          <h4>KEY RESPONSIBILITIES</h4>
                                          <div className="html" dangerouslySetInnerHTML={{__html: career.acf.key_responsibilities}} />
                                       </div>
                                       <button className="btn apply hide-lg" onClick={(e)=>this.apply(e,i)}>Apply</button>
                                    </div>
                                 </div>
                              ))}
                           </div>
                           :
                           <p >Sorry, we currently don't have any job openings.</p>
                        }
                     </Fade>
                  </div>
                  :
                  <div className="center">
                     <div className="loader">
                        <svg className="circular" viewBox="25 25 50 50">
                           <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeminterlimit="10"/>
                        </svg>
                     </div>
                  </div>
               }



               <div id="overlay" />

               <div id="modal" className="modal" >
                  <img id="close" src={Close} width="20px" onClick={this.onCloseModal} />
                  <div id="modal-content">
                     <h3 className="h2">SUBMIT YOUR APPLICATION</h3>
                     {career_apply &&
                        <div>
                           <p className="small category">{career_apply.acf.category}</p>
                           <h3 className="title">{career_apply.title.rendered}</h3>
                           <p className="small subtitle">{career_apply.acf.subtitle}</p>
                        </div>
                     }

                     <form>
                        <div className="input">
                           <p className={required.file ? 'error-text label' : 'label'}  >RESUME/CV*</p>
                           <p className="error">{required.file && `Required*` }</p>

                           <Dropzone onDrop={this.onDrop} id="dragdrop" className="hide-sm-flex"
                              activeStyle={{ backgroundColor: '#0c0a29' }}>
                              <p>Drag & Drop Files Here</p>
                           </Dropzone>
                           <Dropzone onDrop={this.onDrop} id="dragdrop-mobile" className="hide-lg-flex"
                              activeStyle={{ backgroundColor: '#0c0a29' }}>
                              <p>Upload</p>
                           </Dropzone>

                           {apply.files &&
                              <div >
                                 <p id="file-count">{apply.files.length} file(s) uploaded</p>
                                 <div className="display-file" >
                                    {apply.files.map( (file, i) => (
                                       <div key={i} className="display-files" >
                                          <img src={FileIcon} width="16px" />
                                          <p>{file.name}</p>
                                          <img src={CrossIcon} className="close" width="20px" onClick={()=>this.onRemoveFile(i)} />
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           }

                        </div>
                        <div className="input">
                           <p className={required.name ? 'error-text label' : 'label'}  >FULL NAME*</p>
                           <p className="error hide-sm">{required.name && `Required Field*` }</p>
                           <p className="error hide-lg">{required.name && `Required*` }</p>
                           <input placeholder="Full Name" value={apply.name}
                              onChange={(e)=>this.onChange(e, 'name')}  className={required.name ? 'error-input' : '' }
                           />
                        </div>
                        <div className="input">
                           <p className={required.email ? 'error-text label' : 'label'}  >EMAIL*</p>
                           <p className="error hide-sm">{required.email && `Required Field*` }</p>
                           <p className="error hide-lg">{required.email && `Required*` }</p>
                           <input placeholder="Email" value={apply.email}
                              onChange={(e)=>this.onChange(e, 'email')} className={required.email ? 'error-input' : '' }
                           />
                        </div>
                        <div className="input">
                           <p className={required.phone ? 'error-text label' : 'label'}  >PHONE*</p>
                           <p className="error hide-sm">{required.phone && `Required Field*` }</p>
                           <p className="error hide-lg">{required.phone && `Required*` }</p>
                           <input placeholder="Phone" value={apply.phone}
                              onChange={(e)=>this.onChange(e, 'phone')} className={required.phone ? 'error-input' : '' }
                           />
                        </div>
                        <div className="input">
                           <p className="label">CURRENT COMPANY</p>
                           <input placeholder="Current company" value={apply.company}
                              onChange={(e)=>this.onChange(e, 'company')}
                           />
                        </div>
                        <div className="input">
                           <p className="label">ADDITIONAL INFORMATION</p>
                           <textarea placeholder="Additional information" value={apply.additional} ref={i=>this.additional=i}
                              onChange={this.onChangeAdditional} style={{height: `${additional_height}px` }} >
                           </textarea>
                        </div>

                        <ReCaptcha
                           //ref={i=>this.recaptchaRef=i}
                           sitekey="6LfKXaQUAAAAAAFrozaX9yuAHazjN4bTTKFyZeCn"
                           action='action_name'
                           verifyCallback={this.verifyCallback}
                        />

                        <button className="btn" onClick={this.onApply} disabled={ loading_btn ? true : false } >
                           {loading_btn ?
                              <div className="loader-small">
                                 <svg className="circular" viewBox="25 25 50 50">
                                    <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeminterlimit="10"/>
                                 </svg>
                              </div>
                              :
                              'Submit'
                           }
                        </button>

                     </form>
                  </div>
               </div>


               <div id="success-modal" className="modal">
                  <img id="close" src={Close} width="20px" onClick={this.onCloseModal} />
                  <div className="center modal-content">
                     <img id="tick" src={SuccessIcon} width="45px" />
                     <h2 className="h2">SUCCESS!</h2>
                     <p>Your application has been <br/>sent successfully</p>
                  </div>
               </div>

               <div id="error-modal" className="modal">
                  <img id="close" src={Close} width="20px" onClick={this.onCloseModal} />
                  <div className="center modal-content">
                     <img id="tick" src={ErrorIcon} width="45px" />
                     <h2 className="h2">ERROR!</h2>
                     <p>{this.state.error_message}</p>
                  </div>
               </div>

            </div>
            <div id="bottom" className="center">
               <Line right className="hide-sm-flex small-line-3" />
               <p>OR EMAIL US AT <br className="hide-lg" /><a href="mailto:careers@novaluxim.com" >CAREERS@NOVALUXIM.COM</a></p>
            </div>
         </div>
      )
   }


}

export default Careers
