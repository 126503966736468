import React, { Component } from 'react';


class Stars extends Component {

   constructor() {
      super()
      this.state = {
         stars: []
      }
   }

   componentDidMount() {
      let stars = []
      // let count = this.props.count || 70
      let count = this.props.coutn || 45
      const width = window.innerWidth
      const height = document.getElementById('parent').clientHeight
      for (var i = 0; i < count; i++) {
         let boxShadow = ''
         if(Math.floor(Math.random() * 3) == 1 ){
            boxShadow = '0px 0px 10px #ffffff66'
         }
         stars.push( {
            style: {
               left: Math.floor(Math.random() * width ),
               top: Math.floor(Math.random() * height ),
               boxShadow
            }
         } )
      }
      this.setState({ stars })
   }


   render () {
      const {stars} = this.state
      return (
         <div id="stars" >
            {stars.map( (star, i) => (
               <div className="stars" key={i} style={star.style} />
            ))}
         </div>

      )

   }


}

export default Stars
