import React, { Component } from 'react';
import {TweenMax} from 'gsap'
import Line from 'components/Line'
import VisibilitySensor from 'react-visibility-sensor'
import Fade from 'react-reveal/Fade';
import ScrollAnimation from 'react-animate-on-scroll';

import Graphic1 from 'assets/images/about/graphic-1.svg'
import Graphic3 from 'assets/images/about/graphic-3.svg'

class AboutUs extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   componentDidMount() {
      TweenMax.fromTo('#graphic-1', 2, {
         x: 100
      }, {
         x: 0, ease: 'Power3.easeOut', delay: 0.5
      })
      TweenMax.set('#graphic-2', { x: -100 })
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 1;
      },500)
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 0;
      },600)
   }

   onVSGraphic2 = (isVisible) => {
      if(isVisible){
         TweenMax.to('#graphic-2', 2, {
            x: 0, ease: 'Power3.easeOut',
         })
      }
   }

   render () {

      return (
         <div id="about">

            <div id="quote" className="center" >
               <Fade bottom cascade>
                  <div>
                     <h3 className="h3 quotes">
                        "Combining timeless principles and <br className="hide-sm"/>extensive market experience into an adaptive <br className="hide-sm"/>systematic strategy that achieves strong, <br className="hide-sm"/>sustainable, all-weather, risk-adjusted <br className="hide-sm"/>absolute returns"
                     </h3>
                     <p id="name" className="quote-source">
                        - David Hong, <br className="hide-lg" />Founder and Chief Investment Officer
                     </p>
                  </div>
               </Fade>

            </div>

            <Line right />

            <div id="about-us">
               <img src={Graphic1} alt="About Graphic 1" width="600px" id="graphic-1" />
               <Fade bottom cascade>
                  <div id="texts">
                     <h1 className="h1">ABOUT US</h1>
                     <p className="small">NovaLux is a quantitative investment firm that develops and applies systematic investment strategies across a multitude of asset classes in global public markets.</p>
                     <p className="small">NovaLux blends sophisticated systematic trading and portfolio construction tools with extensive investment experience, with a dedication to continuous research, automation and optimization, in order to further enhance returns.</p>
                  </div>
               </Fade>
            </div>
            <Line className="small-line-1" />

            <div id="strategy">
               <VisibilitySensor onChange={this.onVSGraphic2} >
                  <img src={Graphic3} alt="About Graphic 3" width="550px" id="graphic-2" />
               </VisibilitySensor>
               <Fade bottom cascade>
                  <div id="texts">
                     <h1 className="h1">OUR<br/>INVESTMENT<br/>PHILOSOPHY</h1>
                     <p className="small">We believe that states of price disequilibrium are the first and prime drivers of volatility for asset markets in the global universe.</p>
                     <p className="small">Our trading framework is objective and scalable, able to be applied over all market instruments across all geography and asset classes, under all market conditions to deliver strong, consistent returns.</p>

                  </div>
               </Fade>
            </div>
            <div id="bottom" >
               <Line right />
            </div>
         </div>
      )
   }


}

export default AboutUs
